export var ec = '\x1b[0m';
export var bk_d = '\x1b[30m';
export var rd_d = '\x1b[31m';
export var gr_d = '\x1b[32m';
export var yw_d = '\x1b[33m';
export var bl_d = '\x1b[34m';
export var mg_d = '\x1b[35m';
export var cy_d = '\x1b[36m';
export var gy_d = '\x1b[37m';
export var bk = '\x1b[90m';
export var rd = '\x1b[91m';
export var gr = '\x1b[92m';
export var yw = '\x1b[93m';
export var bl = '\x1b[94m';
export var mg = '\x1b[95m';
export var cy = '\x1b[96m';
export var gy = '\x1b[97m';
/*
0: Black
1: Red
2: Green
3: Yellow
4: Blue
5: Magenta
6: Cyan
7: Light gray
*/
